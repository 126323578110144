import React, { Suspense, useCallback, useRef, useMemo, useState } from "react"

import BotScene from "@components/3d/bot-scene"

export default props => {
  const [use3d, setUse3d] = useState(true)

  return (
    <div className="w-auto container mx-auto flex justify-center m-20 mb-5">
      <div className="object-center block h-80 w-full">
        {use3d ? (
          <BotScene />
        ) : (
          <img
            className="object-center block w-auto"
            src="/images/logo-head-white-colored.png"
            data-sal="zoom-in"
            data-sal-duration="700"
            data-sal-easing="ease-in-out"
            alt="thefunbots logo"
          />
        )}
      </div>
    </div>
  )
}
