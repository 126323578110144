import React, {
  Suspense,
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect,
} from "react"
import {
  Canvas,
  Dom,
  extend,
  useLoader,
  useThree,
  useFrame,
} from "react-three-fiber"

import { useDrag } from "react-use-gesture"
import { useSpring, a } from "react-spring/three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import Bot1 from "@3d/bot1"
import Bot1Low from "@3d/bot1-low"

extend({ OrbitControls })

function Controls() {
  const controls = useRef()
  const { scene, camera, gl } = useThree()
  useFrame(() => controls.current.update())
  return (
    <orbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      enableDamping
      dampingFactor={0.1}
      rotateSpeed={0.5}
      enableZoom={false}
      enablePan={false}
    />
  )
}

const camera = null

function Obj() {
  const { size, viewport, camera } = useThree()
  const aspect = size.width / viewport.width
  const [spring, set] = useSpring(() => ({
    position: [12, 0, 15],
    rotation: [0, 0, 0],
    config: { mass: 3, friction: 40, tension: 800 },
  }))
  const bind = useDrag(
    ({ offset: [x, y], vxvy: [vx, vy], down, ...props }) => {
      set({
        position: [x / aspect, -y / aspect, 0],
        rotation: [y / aspect, x / aspect, 0],
      })
      console.log("hey")
    },
    { eventOptions: { pointer: true } }
  )

  camera.setRotationFromAxisAngle([10, 0, 5])

  return <Bot1Low castShadow></Bot1Low>
}

function Camera(props) {
  const ref = useRef()
  const { setDefaultCamera } = useThree()
  // This makes sure that size-related calculations are proper
  // Every call to useThree will return this camera instead of the default camera
  useEffect(() => void setDefaultCamera(ref.current), [])
  return <perspectiveCamera ref={camera} {...props} />
}

export default props => {
  const [useLowModel, setUseLowModel] = useState(true)
  const [camera, setCamera] = useState({
    zoom: 160,
    near: 1,
    far: 40,
    //position: [0, 0, 0],
  })

  return (
    <Canvas
      // concurrent
      gl={{ alpha: true }}
      camera={camera} //
      //camera={{ position: [0, 0, 5], fov: 25 }}
      onCreated={({ gl, camera }) => {
        //camera.lookAt(0, 0, 0)
        //gl.setClearColor("#fff0ea")
        //gl.toneMapping = THREE.Uncharted2ToneMapping
        //gl.toneMapping = THREE.ACESFilmicToneMapping
        //gl.outputEncoding = THREE.sRGBEnoding
      }}
      pixelRatio={2}
      //gl2={true}
      sRGB={true}
      //invalidateFrameloop
      orthographic={true}
      shadowMap
    >
      {/*<Camera position={[0, 0, 10]} />*/}

      {/*<fog attach="fog" args={["#fff0ea", 10, 60]} />*/}
      <ambientLight intensity={0.4} color="#FCA799" />
      <pointLight position={[-100, 100, -50]} intensity={1} color="#fff" />
      <pointLight position={[100, -100, -50]} intensity={2} color="#83F1FF" />

      <Suspense fallback={<Dom center>loading ...</Dom>}>
        {/*<Rig>*/}
        {useLowModel ? (
          <Bot1Low
          // onPointerMove={() =>
          //   setCamera({
          //     position: [100, 100, 0.5],
          //   })
          // }
          />
        ) : (
          <Bot1 />
        )}
        {/*<Obj />*/}

        {/*<mesh*/}
        {/*  scale={[1000, 1000, 1]}*/}
        {/*  rotation={[-Math.PI / 2, 0, 0]}*/}
        {/*  onPointerOver={e => e.stopPropagation()}*/}
        {/*>*/}
        {/*  <planeBufferGeometry attach="geometry" />*/}
        {/*  <meshBasicMaterial*/}
        {/*    attach="material"*/}
        {/*    transparent*/}
        {/*    opacity={0.7}*/}
        {/*    color="skyblue"*/}
        {/*  />*/}
        {/*</mesh>*/}
        {/*</Rig>*/}
        <Controls />
      </Suspense>
    </Canvas>
  )
}
